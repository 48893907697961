<template>
  <div class="video-html">
    <video
      ref="videoElement"
      :src="props.videoUrl"
      :controls="!props.autoplay"
      :muted="props.autoplay"
      :loop="props.loop"
      playsinline
      :autoplay="props.autoplay"
      preload="none"
    />
  </div>
</template>

<script>
export default {
  name: 'WidgetVideo',
}
</script>

<script setup>
import { debounce } from '~/assets/js/utils.js'

const props = defineProps({
  videoUrl: String,
  autoplay: Boolean,
  loop: Boolean,
})

const videoElement = ref(null)
let observer = null

onMounted(() => {
  observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        // If the video is visible in the viewport and autoplay is not prevented
        if (entry.isIntersecting) {
          entry.target.play()
        } else {
          entry.target.pause()
        }
      })
    },
    {
      threshold: 0.5, // trigger when at least 50% of the video is visible
    }
  )

  observer.observe(videoElement.value)
})

onUnmounted(() => {
  // Disconnect the observer when the component unmounts
  if (observer) {
    observer.disconnect()
  }
})
</script>

<style lang="scss">
@import './style.scss';
</style>
